<template>
<div class="col-6">
  
    <div class="oti_square">
      

    <div class="flex justify-content-between">
        <div class="os_number">
            O.T - {{ otiName(order.id) }}
        </div>
        <div class=""  v-if="order.machine_id.id !== 1">
            <div :class="'status_box status-' + order.status">{{ statusName }}</div>
        </div>
                <div class=""  v-else>
            <div :class="'status_box status-' + order.status"> {{ statusName }} </div>
        </div>
    </div>

    <div class="flex justify-content-between" v-if="order.machine_id.id !== 1" >
        <div class="os_detail">
           {{ order.piece }}
        </div>
        <div style="" class="os_clock">
           {{ secsParsed(order.time)}} 
        </div>
    </div>
    <div class="flex justify-content-between" v-else >
        <div class="os_detail">
           {{ order.piece }}
        </div>
        <div style="" class="os_clock">
           <small  class="os_detail"> TIEMPO DE CICLO: </small> {{ secsParsed(order.time)}} 
        </div>
    </div>

    <div class="flex justify-content-between" v-if="order.machine_id.id !== 1">
        <div class="os_detail">
            <i class="pi pi-fw pi-user mr-3"> </i> 
            {{ giveMeName(order.current_operator, 'usuario') }} 
        </div>
        <div>
              
            <OtiActionsSmall :order="order" :userGroup="this.userGroup" />
            
        </div>
    </div>
    <div class="flex justify-content-between" v-else>
        <div class="os_detail">
            <i class="pi pi-fw pi-cog mr-3"> </i> 
            TORNO FANUC
        </div>
        <div>
               <div style="" class="">
           <small  class="os_detail"> TIEMPO TOTAL: {{ secsParsed(order.total_time)}} </small> 
        </div>
            
        </div>
    </div>

    </div>
</div>
</template>

<script>
import OtiActionsSmall from "./../OtiActionsSmall";
import CrudService from "./../../services/crud.service.js";


export default {
  components: {
    OtiActionsSmall,
  },
  props: ["order", "size", "userGroup"],
  data() {
    return {
        dinamycStatus: this.order.status,
        elapsed: 0,
        clients: [],
        users: [],
        machines: [],
      
    };
  },
  created() {

  },
  mounted() {
    this.dinamycStatus = this.order.status;
    //CrudService.getCRUD("cliente").then((data) => (this.clients = data));
    CrudService.getCRUD("usuario").then((data) => (this.users = data));
    //CrudService.getCRUD("maquinaria").then((data) => (this.machines = data));

        setInterval(
      function () {
        if (this.order.status == 1 || this.order.status == 7) {
          this.order.time++;
        } else {
        }
      }.bind(this),
      1000
    );

  },
  methods: {
      otiName(id) {
        // str pad left 0000
        return ("0000" + id).slice(-4);        
      },
      giveMeName(id, table) {

      if(table == 'cliente'){
        let exist = this.clients.find((client) => client.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'maquinaria'){
        let exist = this.machines.find((machine) => machine.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name;
        
        }

      }

      if(table == 'usuario'){
        
        let exist = this.users.find((user) => user.id === id);

        if (!exist) {
          return " - ";
        } else {
          return exist.name + " " + exist.last_name;
        
        }

      }

    


    },
    actionClicked(e) {
      this.dinamycStatus = e;

      console.log("actionClicked", e, this.elapsed);
    },
    secsParsed: function (sec) {
      
      var hours = Math.floor(sec / 3600);
      hours >= 1 ? (sec = sec - hours * 3600) : (hours = "00");
      var min = Math.floor(sec / 60);
      min >= 1 ? (sec = sec - min * 60) : (min = "00");
      sec < 1 ? (sec = "00") : void 0;

      let ph = "00";
      if (hours >= 1) {
        ph = String(hours).padStart(2, "0");
      }
      let sec2 = "00";
      if (sec >= 1) {
        sec2 = String(sec).padStart(2, "0");
      }

      min.toString().length == 1 ? (min = "0" + min) : void 0;

      return ph + ":" + min + ":" + sec2;
    },
  },
  computed: {
    statusClass() {
      switch (this.order.status) {
        case 0:
          return "pi pi-user";
        case 1:
          return "pi pi-play";
        case 2:
          return "pi pi-pause";
        case 3:
          return "pi pi-check";
        case 4:
          return "pi pi-star";
        default:
          return "pi pi-exclamation-circle";
      }
    },
    statusName() {
      switch (this.order.status) {
        case 0:
          return " Sin Iniciar";
        case 1:
          return " En proceso";
        case 2:
          return " En pausa";
        case 3:
          return " En calidad";
        case 4:
          return " Aprobada";
        case 5:
          return " En pausa / DESAPROBADA";
        case 6:
          return " En torno / EN COLA";
          case 7:
          return " En torno / EN PROCESO";
          case 8:
          return " En incidencia";
        default:
          return " - ";
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.os_number {
    text-align: left;

letter-spacing: 0px;
color: #9A9A9A;
}

.os_detail {
text-align: left;
font-size: 16px;
line-height: 40px;
letter-spacing: 0px;
color: #0C0C0C;
font-weight: bold;
}

.os_clock {
text-align: left;
font-size: 32px;
line-height: 40px;
letter-spacing: 0px;
color: #0C0C0C;
}
.oti_square {
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #D8D8D8;
padding: 24px;
}


.status_box {

          display: inline-block;
          
          font-weight: bolder;
          text-align: center;
          padding: 5px;
          text-transform: uppercase;
        }
        .status-7 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
        }      
        .status-6 {
            border: 1px solid #2ce4d49a;
            background-color: #2ce4d535;
            color: #000;
        }
        .status-5 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
            color: #000;
        }
        .status-4 {
            border: 1px solid #2ce4d49a;
            background-color: #2ce4d535;
        }
        .status-3 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
        }
        .status-2 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
        }
        .status-1 {
            border: 1px solid #44b73ae3;
            background-color: #44b73a71;
          
        }
        .status-0 {
            border: 1px solid #d8d8d89a;
            background-color: #d8d8d89a;
        }

.oti_clock {
  padding: 5px 25px 5px 25px;
  text-transform: uppercase;
  line-height: 50px;
  font-size: 26px;
  & > div i {
    font-size: 22px;
    line-height: 30px;
  }
  &.st_bg_color_3 {
    border: 1px solid #44b73a71;
    background-color: #44b73a71;
    & > div i {
      color: #44b73a;
    }
  }
  &.st_bg_color_2 {
    border: 1px solid #ff99005b;
    background-color: #ff99005b;
    & > div i {
      color: #ff9800;
    }
  }
  &.st_bg_color_1 {
    border: 1px solid #1c96c256;
    background-color: #1c96c256;
    & > div i {
      color: #0b3b5a;
    }
  }
  &.st_bg_color_0 {
    background-color: #d8d8d89a;
    background-color: #d8d8d89a;
    & > div i {
      color: #807f7f;
    }
  }
}
</style> 
