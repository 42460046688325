import api from "./api";
import TokenService from "./token.service";
class AuthService {
  login({ username, password }) {
    return api
      .post("/api/token/", {
        username,
        password
      })
      .then((response) => {
        if (response.data.access) {
          
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  loginOP({ id }) {
    return api
      .get("/usuario/usuario/gettoken/" + id + "/")
      .then((response) => {
        if (response.data.access) {
          
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }
  // getUser() {
  //   return api.get("/usuario/usuario/bytoken/").then((response) => {
  //     console.log(response)
  //     return response.data;
  //   }).catch((error) => {
  //     return error.response;
  //   });
  // }
  logout() {
    TokenService.removeUser();
  }
  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }
}
export default new AuthService();