<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden"
    >


        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">

            <div class="col-12 xl:col-6 login_box">
                <div class="h-full w-full m-0 py-7 px-4" >
                    <div class="text-center mb-2">
                        <div class="col-12 xl:mt-0 text-center">
                            <img :src="'/images/' + this.appSlug +'_logo.png'" alt="logo" class="w-3" />
                        </div>
                        <div class="col-12 xl:mt-0 text-center">
                            
                            <small>{{ this.appTitle }}</small>
                        </div>
                        <div class="col-12 mt-5 xl:mt-0 text-center">
                            <h4>SISTEMA DE PRODUCCIÓN</h4>
                        </div>
                        <div class="col-12 mt-5 xl:mt-0 text-center">
                            <h6>INGRESO PARA ADMINISTRADORES</h6>
                        </div>

                
                        
                        <InputText id="email1" v-model="username" type="text" class="w-full mb-3" placeholder="Usuario" style="padding:1rem;" />
                
                        
                        <Password :feedback="false" id="password1" v-model="password" 
                        placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>

                                                            </div>

                                                            <div class="w-full md:w-75 mx-auto">
                                                                {{ this.message }}
                                                            </div>
                    <div class="w-full md:w-75 mx-auto">
                        

                        <Button label="INGRESAR" class="w-full p-3 text-md mt-3" @click="login()"></button>

                        <!-- <Button label="CAMBIAR A INGRESO PARA OPERARIOS" class="mt-2 w-full p-3 p-button-outlined  text-md" @click="switchLoginMode()"></button> -->
                    </div>
                </div>
            </div>
        </div>
            
    </div>
</template>

<script>
import UserService from './../services/user.service.js'
import OperatorService from "./../services/operator.service.js";
import axios from 'axios';

export default {
    data() {
        return {
            message: '',
            email: '',
            password: '',
            checked: false,
            loginMode: null,
            operators: [
            ]
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push('/');
        }
        //this.getOperators();
    },

    methods: {
        getOperators() {
            OperatorService.getOperators().then(response => {
                
                this.operators = response;
            });
        },
        switchLoginMode(){
            this.loginMode = this.loginMode === 'admin' ? 'operario' : 'admin';
        },
        
        login(operator) {
                let user = { 
                    username: this.username, 
                    password: this.password 
                };

                if(operator){

                   this.$store.dispatch('auth/loginoperator', operator).then(
                    () => {
                        console.log('ok?')
                        this.$router.push("/");
                    },
                    (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                );

                }
                
                this.loading = true;
                this.$store.dispatch("auth/login", user).then(
                    () => {
                        this.$router.push("/");

                        // UserService.getData().then(data => {
                        //     this.$store.commit("auth/setUser", data);
                        // }).catch(error => {
                        //     this.loading = false;
                        //     this.message = error.message;
                        // }).finally(() => {
                        //     this.loading = false;
                        //});

                        // this.$store.dispatch("auth/getUser").then((data) => {
                        //     this.$router.push("/profile");
                        // }).catch(() => {
                        //     this.loading = false;
                        //     this.message = "Error al obtener usuario";
                        // }).finally(() => {
                        //     this.loading = false;
                        // });

                    },
                    (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    }
                );

        }

    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}

h4 {
    color: #0b3b5a;
}

small {
    color: #9A9A9A;
}

.login_box {
    border: 1px solid var(--unnamed-color-d8d8d8);
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #D8D8D8;
}
</style>