import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from './components/Dashboard.vue';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
    },
    {
        path: '/cliente',
        name: 'cliente',
        component: () => import('./pages/Clientes.vue')
    },
    {
        path: '/proveedor',
        name: 'proveedor',
        component: () => import('./pages/Proveedores.vue')
    },
    {
        path: '/maquinaria',
        name: 'maquinaria',
        component: () => import('./pages/Maquinaria.vue')
    },
    {
        path: '/logistica',
        name: 'logistica',
        component: () => import('./pages/Logistica.vue')
    },
    {
        path: '/material',
        name: 'material',
        component: () => import('./pages/Materiales.vue')
    },
    {
        path: '/usuario',
        name: 'usuario',
        component: () => import('./pages/Users.vue')
    },
    {
        path: '/produccion',
        name: 'produccion',
        component: () => import('./pages/production/list.vue')
    },
    {
        path: '/produccion/:id',
        name: 'ordenprod',
        component: () => import('./pages/production/show.vue')
    },
    {
        path: '/orden/:id',
        name: 'oti',
        component: () => import('./pages/production/oti_show.vue')
    },
    {
        path: '/produccion/:id/addorder',
        name: 'CreateOti',
        component: () => import('./pages/production/oti_create.vue')
    },
    {
        path: '/produccion/crear',
        name: 'CreateProduccion',
        component: () => import('./pages/production/create.vue')
    },
    {
        path: '/estadisticas/:of',
        name: 'Stats',
        component: () => import('./pages/Stats.vue')
    },
    {
        path: '/mantenimiento',
        name: 'Mantenimiento',
        component: () => import('./pages/Mantenimiento.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
