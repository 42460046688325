<template>
<div>

	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle"  :user="userData" v-if="userData" />

        <div class="layout-sidebar" @click="onSidebarClick" v-if="userData && userData.groups && userData.groups[0] == 1" >
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick"/>
        </div>
        <div class="layout-sidebar" @click="onSidebarClick" v-if="userData && userData.groups && userData.groups[0] == 3" >
            <AppMenu :model="menuCalidad" @menuitem-click="onMenuItemClick"/>
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

		<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" @change-theme="changeTheme" />
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>

</div>

</template>

<script>
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
import Login from './pages/Login.vue';
import UserService from "./services/user.service";

export default {
    emits: ['change-theme'],

    mounted() {
        if (!this.currentUser) {
            this.$router.push('/operarios');
        }

    UserService.getUserBoard().then(
      (response) => {
        this.userData = response.data;
        if(this.userData && this.userData.groups && this.userData.groups[0] == 2){
            this.onMenuToggle();
        }
      },
      (error) => {
       
       
       // remove user from local storage to log user out
         localStorage.removeItem('user');

        this.$router.push('/operarios');

        this.userData =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );



    },
    data() {
        return {
            isLogged: false,
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            userData: {},
            menuCalidad: [
                {
items: [
            {label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'},
            {label: 'Produccion', icon: 'pi pi-fw pi-tablet', to: '/produccion' },
            {label: 'Maquinaria', icon: 'pi pi-fw pi-share-alt', to: '/maquinaria'},
            {label: 'Materiales', icon: 'pi pi-fw pi-table', to: '/material'},
            {label: 'Mantenimiento', icon: 'pi pi-fw pi-calendar', to: '/mantenimiento'},
            ]
                }
                ],
            menu : [
                {
                    //label: 'Panel',
                    // items: [{
                    //     label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'
                    // }]
                },
				{
					//label: 'Terceros', icon: 'pi pi-fw pi-sitemap',
					items: [
                        {label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/'},
                        {label: 'Produccion', icon: 'pi pi-fw pi-tablet', to: '/produccion' },
        				{label: 'Clientes', icon: 'pi pi-fw pi-list', to: '/cliente'},
                        {label: 'Proveedores', icon: 'pi pi-fw pi-list', to: '/proveedor'},
						{label: 'Maquinaria', icon: 'pi pi-fw pi-share-alt', to: '/maquinaria'},
						{label: 'Materiales', icon: 'pi pi-fw pi-table', to: '/material'},
                        {label: 'Mantenimiento', icon: 'pi pi-fw pi-calendar', to: '/mantenimiento'},
                        //{label: 'Logística ', icon: 'pi pi-fw pi-directions', to: '/logistica'},
                        //{label: "Cotizaciones", icon: "pi pi-fw pi-dollar", to: "/cotizaciones"},
                        {label: 'Estadísticas', icon: 'pi pi-fw pi-chart-line',
                            items: [
                                {label: 'Clientes', icon: 'pi pi-fw pi-percentage', to: '/estadisticas/clientes'},
                                {label: 'Operarios', icon: 'pi pi-fw pi-users', to: '/estadisticas/operarios'},
                                {label: 'Facturación', icon: 'pi pi-fw pi-money-bill', to: '/estadisticas/facturacion'},
                                ]
                        },
                        {label: 'Usuarios', icon: 'pi pi-fw pi-user', to: '/usuario' }
 
					]
				},
                
            ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    created() {

    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            //event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        changeTheme(event) {
            this.$emit('change-theme', event);
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo.jpeg" : "images/logo.jpeg";
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppConfig': AppConfig,
        'AppFooter': AppFooter,
        'Login': Login,
    }
}
</script>

<style lang="scss">
@import './App.scss';

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #0b3b5a;
    border-color: #041f30;
    color: #fff;
}

</style>
