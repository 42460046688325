<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			
			<span>Sistema de Producción - {{ this.appTitle }}</span>
		</router-link>
		<button v-if="user && user.groups && user.groups[0] !== 2" class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>

		</button>
		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>

				<button class="p-link layout-topbar-button-user">
					
					<i class="pi pi-user inverted-link"></i>					
					
					{{ user['name'] }} {{ user['last_name'] }}

				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-logout mt-1"  @click="logout()">
				Cerrar Sesión
				</button>
			</li>
		</ul>
	</div>1
</template>

<script>
export default {
	props: ["user"],
	data() {
		return { 

		}
	},
	mounted() {
	// 	if(this.user && this.user.groups && this.user.groups[0] == 2){
    //        this.onMenuToggle();

    //    }
	},
    methods: {
		logout(){

			// delete token from local storage
			localStorage.removeItem(process.env.VUE_APP_SLUG + '_profile');
			localStorage.removeItem('user');

			// redirect to login page

			window.location.href = "/"

		},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo.jpeg' : 'images/logo.jpeg';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>