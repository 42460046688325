    <template>
  <div>
    <div class="col-12">
      <div class="flex justify-content-around flex-wrap">
        <h2 class="m-0 mb-3">
          <span class="" style="color: #0b3b5a">{{ orders.filter((order) => order.status == 1).length }}</span>
          ORDENES EN PROCESO
        </h2>
        <h2 class="m-0 mb-3">
          <span class="" style="color: #0b3b5a">{{ orders.filter((order) => order.status == 0).length }}</span>
          ORDENES SIN INICIAR
        </h2>
        <h2 class="m-0 mb-3">
          <span class="" style="color: #0b3b5a">{{ orders.filter((order) => order.status == 2).length }}</span>
          ORDENES EN PAUSA
        </h2>
         <h2 class="m-0 mb-3">
          <span class="" style="color: #0b3b5a">{{ orders.filter((order) => order.status == 3).length }}</span>
          ORDENES EN CALIDAD
        </h2>
      </div>

  <TabView ref="tabview1">
                    <TabPanel header="EN PROCESO">
                    <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status === 1)" :key="order.id" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="SIN INICIAR">
                    <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status === 0)" :key="order.id" :order="order" /> </div>
                </TabPanel>

                <TabPanel header="EN PAUSA">
                    <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status === 2)" :key="order.id" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="EN CALIDAD">
                    <div class="grid"> <OtiForOp v-for="order in orders.filter((order) => order.status === 3)" :key="order.id" :order="order" /> </div>
                </TabPanel>
                
  </TabView>

<!-- 
     
- <div class="">
    TABLA DE CONTROL
</div>

        <div class="mt-3">
          <div class="col-12">
            <OtiClock
              :order="order"
              :opGroup="opGroup"
            />
          </div>
        </div>
				<p>{{ order }}</p> 
      </div> -->
    </div>
  </div>
</template>

<script>
import OtiClock from "./../OtiClock.vue";
import CrudService from "./../../services/crud.service.js" 
import OtiForOp from "./OtiForOp.vue";
export default {
  props: ['opId', 'opGroup'],
  components: {
    OtiClock,
    OtiForOp
  },
  data() {
    return {
      orders: [],
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      
      CrudService.getCRUD("orden", "otibyuser/"+this.opId).then((data) => (this.orders = data));
    },
  },
};
</script>
