import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;

class OperatorService {

        getOperators() {

                return axios.get(
                        API_URL + '/usuario/usuario/operarios',
                        { headers: authHeader() })
                        .then(response => {

                            if(response.status == 200){
                                return response.data;
                            }

                        }).catch(error => {

                            return error.response;

                    })
                    

                // console.log(data)
                
                // return axios.put(
                //         API_URL + '/orden/orden/' + data.id + '/',
                //         data,
                //         { headers: authHeader() })
                //         .then(response => {
        
                //             if(response.status == 200){
                //                 return response.data;
                //             } 
        
                //         }).catch(error => {
                            
                //             return error.response;
        
                //     })
        }


        
}


export default new OperatorService();