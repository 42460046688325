import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_ROOT_API;

class CrudService {

        getCRUD(data1, data2 = data1, data3 = null) {

                return axios.get(
                    API_URL + '/' + data1 + '/' + data2 + '/' + (data3 == null ? '' : data3 + '/'),
                    { headers: authHeader() })
                    .then(response => {
                        return response.data;
                    }).catch(error => {
                        return error.response.data;
                })


        }

        createCRUD(path, extra_path = path, data) {

        return axios.post(
                API_URL + '/' + path + '/' + extra_path + '/',
                data,
                { headers: authHeader() })
                .then(response => {

                    if(response.status == 200){
                        return response.data;
                    } 

                }).catch(error => {
                    
                    return error.response;

            })
        }  


        updateCRUD(path, extra_path = path, data, optional = '/') {

                let headers = authHeader();
                if(data.csrfmiddlewaretoken){
                    headers['X-CSRFToken'] = data.csrfmiddlewaretoken;
                }
                
                return axios.put(
                        API_URL + '/' + path + '/' + extra_path + '/' + data.id + '/' + optional,
                        data,
                        { headers: headers })
                        .then(response => {
        
                            if(response.status == 200){
                                return response.data;
                            } 
        
                        }).catch(error => {
                            
                            return error.response;
        
                    })
        }

        deleteCRUD(path, extra_path = path, id) {

                return axios.delete(
                        API_URL + '/' + path + '/' + extra_path + '/' + id + '/',
                        { headers: authHeader() })
                        .then(response => {
        
                            if(response.status == 200){
                                return response.data;
                            } 
        
                        }).catch(error => {
                            
                            return error.response;
        
                    })
        }

        
}


export default new CrudService();