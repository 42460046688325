<template>
	<div>
  <div class="grid">

    <div class="col-12">

      <ResumedCharts />

    </div>

		<div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #9A9A9A">
        
        <h1>{{ countStatus(0)   }}</h1>

        <h3>SIN INICIAR</h3>
  
			</div>
		</div>
		<div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #1C95C2">
        
        <h1>{{ countStatus(1) + countStatus(7)}}</h1>

        <h3>EN PROCESO</h3>
  
			</div>
		</div>
		<div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #EEC216">
        
        <h1>{{ countStatus(2) +  countStatus(5)}}</h1>

        <h3>EN PAUSA</h3>
  
			</div>
		</div>

    <!--#7E57C2 EN CALIDAD ? --->

		<div class="col-3" >
			<div class="card" style="border-bottom: 5px solid #66BB6A">
        
        <h1>{{ countStatus(4)}}</h1>

        <h3>FINALIZADAS</h3>
  
			</div>
		</div>


  </div> 


  <TabView ref="tabview1">
              <TabPanel header="EN PROCESO">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) =>{ return order.status === 1  || (order.machine_id.id == 1 && order.status !== 4)})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
                <!-- <TabPanel header="ASIGNADAS">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => order.status === 4)" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>-->
                <TabPanel header="SIN ASIGNAR / SIN INICIAR">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => {return order.status === 0 && order.machine_id.id !== 1})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel> 
                <TabPanel header="EN PAUSA">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => { return order.status === 5 || order.status === 2})" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="EN CALIDAD">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => order.status === 3)" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
                <TabPanel header="FINALIZADAS">
                    <div class="grid"> <OtiSquare v-for="order in orders.filter((order) => order.status === 4)" :key="order.id" :userGroup="this.opGroup" :order="order" /> </div>
                </TabPanel>
                
                <TabPanel header="TODAS" style="background-color: #F5F5F5">
                    <div class="grid"> <OtiSquare v-for="order in orders" :key="order.id" :userGroup="opGroup" :order="order" /> </div>
                </TabPanel>
  </TabView>


  </div>

</template>

<script>
import CrudService from "./../../services/crud.service.js" 
import OtiSquare from "./OtiSquare.vue";
import ResumedCharts from "./ResumedCharts.vue";

export default {
  props: ['opGroup'],
  data() {
    return {
      orders: [],
    }
  },
  components: {
    OtiSquare,
    ResumedCharts
  },
	computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    currentGroup() {
      return this.$store.state.auth;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }

    this.getOrders();
  }, 
  methods: {
    countStatus(status) {
      return this.orders.filter((order) => order.status === status).length;
    },
    getOrders() {

        CrudService.getCRUD("orden", "orden").then((data) => (this.orders = data));


    },

  }

}
</script>

<style scoped>
    .motiveButton {
        
        font-size: 1.8rem;
        text-transform: uppercase;
        margin: 1%;
        background: #ff99005b;
        color: black;
        border: 1px solid #ff99005b;
        &:hover {
            background: #ff9900;
            color: black;
            border: 1px solid #ff9900;
        }
    }
</style>